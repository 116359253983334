import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import ScrollMonitoringWrapper from '../ScrollMonitoringWrapper'

class Video extends Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { videoSrcURL, videoTitle = '' } = this.props
    const { windowHeight, animated } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={() => setTimeout(() => this.setState({ animated: true }), 500)}>
        <ReactPlayer url={videoSrcURL} playing={animated} title={videoTitle} playsinline={true} loop={true} muted={true}  width="100%" height="auto" />
      </ScrollMonitoringWrapper>
    )
  }
}

export default Video
