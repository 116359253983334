import React from 'react'
import Image from '../../atoms/Image'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './portfolio-block.module.scss'

class PortfolioBlock extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
    }
  }

  render () {
    const { title, caption, image = {}, inverted, withShadow } = this.props
    const { animated } = this.state

    return (
      <div className={`${styles.item} ${inverted && styles.inverted} ${animated && styles.animated}`}>
        <ScrollMonitoringWrapper threshold={[0.75]} isInViewportEvent={() => this.setState({ animated: true })}>
          {
            image && image.localFile &&
            <div className={`${styles.imageWrapper} ${withShadow && styles.withShadow}`}>
              <Image {...image} />
            </div>
          }
          <div className={styles.copy}>
            <h4><span>{title}</span></h4>
            <div><span dangerouslySetInnerHTML={{ __html: caption }} /></div>
          </div>
        </ScrollMonitoringWrapper>
      </div>
    )
  }
}

export default PortfolioBlock
