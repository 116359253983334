import React from 'react'
import Image from '../../atoms/Image'
import Video from '../../atoms/Video'
import PortfolioBlock from '../../molecules/PortfolioBlock'
import styles from './portfolio-section.module.scss'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'

class PortfolioSections extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
    }
  }

  render () {
    const { firstSection, inverted, number = 1, background = '#FFFFFF', imageAlt, content = {} } = this.props
    const { animated } = this.state

    return (
      <div className={`${styles.portfolioSection} ${firstSection && styles.firstSection} ${inverted && styles.inverted} ${animated && styles.animated}`} style={{ backgroundColor: background }}>
        <ScrollMonitoringWrapper isInViewportEvent={() => this.setState({ animated: true })}>
          <div className={styles.heading}>
            <span className={styles.number}><span>0{number}</span></span>
            <div className={`container ${styles.container}`}>
              <h4><span dangerouslySetInnerHTML={{ __html: content.title }} /></h4>
              <div className={styles.caption} dangerouslySetInnerHTML={{ __html: content.caption }} />
            </div>
          </div>
          </ScrollMonitoringWrapper>
        {
          content.large_image &&
          <div className={styles.banner}>
            <div className="container">
              {
                content.video_source
                ? <div className={styles.videoWrapper}>
                      <Video
                      videoSrcURL={content.video_source}
                      videoTitle={imageAlt}
                      />
                    </div>
                  : <div className={styles.imageWrapper}>
                      <Image fluid={content.large_image.localFile.childImageSharp.sizes} />
                    </div>
              }
            </div>
          </div>
        }
        {
          content.blocks && content.blocks.length > 0 &&
          <div className={styles.items}>
            <div className="container">
              {
                content.blocks.map(({ caption, title, image = {}, show_shadow }) => <PortfolioBlock title={title} caption={caption} image={image} inverted={inverted} withShadow={show_shadow} />)
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default PortfolioSections
