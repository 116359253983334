import React from 'react'
import Image from '../../atoms/Image'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './portfolio-hero.module.scss'

class TestHero extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      animateBottom: false
    }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ animated: true }), 250)
  }
  render () {
    const { title, caption, bottomCaption, bottomTitle, excerpt, image = {}, services, clients, link } = this.props
    const { animated, animateBottom } = this.state

    return (
      <div className={`${styles.portfolioHero} ${animated && styles.animated}`}>
        <div className={styles.topSection}>
          <div className="container">
            <h4><span>Portfolio</span></h4>
            <div className={styles.headings}>
            <h1 className={title === 'MinterEllisonRuddWatts' && styles.smTitle}><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
            { caption && <div className={styles.caption} dangerouslySetInnerHTML={{ __html: caption }} /> }
            </div>
          </div>
          {
            image && image.localFile &&
            <div className={styles.imageWrapper}>
              <Image {...image} />
            </div>
          }
        </div>
        <ScrollMonitoringWrapper threshold={[0.75]} isInViewportEvent={() => this.setState({ animateBottom: true })}>
          <div className={`${styles.bottomSection} ${animateBottom && styles.animate}`}>
            <div className="container">
                <h3><span dangerouslySetInnerHTML={{ __html: bottomTitle }} /></h3>
                <div className={styles.details}>
                  <div className={styles.link}><a target="_blank" rel="noopener noreferrer" href={link}><i className="fa fa-link"></i>{link}</a></div>
                  <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: bottomCaption }} />
                  <div className={styles.lists}>
                    {
                      services &&
                      <dl>
                        <dt>Services:</dt>
                        { services.map(({ title }) => <dd>{title}</dd>) }
                      </dl>
                    }
                    {
                      clients &&
                      <dl>
                        <dt>Cleints:</dt>
                        { clients.map(({ title }) => <dd>{title}</dd>) }
                      </dl>
                    }
                  </div>
                </div>
            </div>
          </div>
        </ScrollMonitoringWrapper>
      </div>
    )
  }
}

export default TestHero
