import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import PortfolioHero from '../components/molecules/PortfolioHero'
import PortfolioSections from '../components/organisms/PortfolioSections'

export default ({ pageContext }) =>
  <Layout>
    <PortfolioHero
      title={pageContext.title}
      caption={pageContext.content}
      bottomTitle={pageContext.acf.title}
      bottomCaption={pageContext.acf.caption}
      image={pageContext.acf.banner_image}
      excerpt={pageContext.excerpt}
      services={pageContext.acf.services}
      clients={pageContext.acf.clients}
      link={pageContext.acf.portfolio_url}
    />
    { pageContext.acf.sections && pageContext.acf.sections.map(({ section }, i) =>
        <PortfolioSections
          firstSection={i === 0}
          inverted={i % 2}
          background={i % 2 ? "#F9F9F9" : "#FFFFFF" }
          content={section}
          number={i + 1}
          imageAlt={pageContext.title}
        />
      )
    }
  </Layout>
